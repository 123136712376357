import type {
  ClassroomCreate,
  ClassroomDetail,
  Classroom
} from 'features/Classroom/ClassroomInterfaces';
import type { Activity } from 'features/Activity/ActivityInterfaces';
import fastapi from 'features/Common/utils/fastapi';

export const createClassroom = async (
  classroomData: ClassroomCreate,
  token: string
): Promise<Classroom> => {
  return fastapi<Classroom>('post', '/classrooms/create', classroomData, token);
};

export const getClassroom = async (
  id: string,
  token: string
): Promise<ClassroomDetail> => {
  return fastapi<ClassroomDetail>('get', `/classrooms/${id}`, {}, token);
};

export const joinClassroom = async (
  classroomId: string,
  token: string
): Promise<void> => {
  return fastapi<void>('post', `/classrooms/join/${classroomId}`, {}, token);
};

export const approveJoinClassroom = async (
  classroomId: string,
  studentId: string,
  token: string
): Promise<void> => {
  return fastapi<void>('post', `/classrooms/approve_join/${classroomId}`, { studentId }, token);
};

export const getActivities = async (
  classroomId: string,
  token: string
): Promise<Activity[]> => {
  return fastapi<Activity[]>('get', `/classrooms/${classroomId}/activities`, {}, token);
};
