import fastapi from 'features/Common/utils/fastapi';
import {
  Question,
  Answer,
  PostQuestionParams,
} from './QuestionInterfaces';

export const postQuestion = async (
  params: PostQuestionParams,
  token: string
): Promise<Question> => {
  return fastapi<Question>('post', '/question/create', params, token);
};

export const fetchQuestions = async (token: string): Promise<Question[]> => {
  return fastapi<Question[]>('get', '/question/list', {}, token);
};

export const getQuestionDetail = async (
  id: string,
  token: string
): Promise<Question> => {
  return fastapi<Question>('get', `/question/detail/${id}`, {}, token);
};

export const postAnswer = async (
  questionId: string,
  content: string,
  token: string
): Promise<Answer> => {
  return fastapi<Answer>('post', `/answer/create/${questionId}`, { content }, token);
};
