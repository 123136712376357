import fastapi from 'features/Common/utils/fastapi';
import type { User, RegisterFormData, AuthResponse } from 'features/Auth/AuthInterfaces';

// ApiError 인터페이스 정의
interface ApiError {
  message: string;
  // 필요한 경우 다른 필드 추가
}

// Helper function to store token
const storeToken = (token: string) => {
  localStorage.setItem('access_token', token);
};

// Helper function to retrieve token
const getStoredToken = (): string | null => {
  return localStorage.getItem('access_token');
};

// Login function
export const login = async (
  username: string,
  password: string
): Promise<AuthResponse> => {
  const response = await fastapi<AuthResponse>('post', '/user/login', { username, password });
  const { access_token } = response.token;
  storeToken(access_token);
  return response;
};

// Register function
export const register = async (formData: RegisterFormData): Promise<User> => {
  // RegisterFormData를 Record<string, unknown>으로 변환
  const formDataRecord: Record<string, unknown> = {
    username: formData.username,
    email: formData.email,
    password: formData.password,
    role: formData.role,
    // 다른 필드가 있다면 여기에 추가
  };

  const response = await fastapi<AuthResponse>('post', '/user/register', formDataRecord);
  const { access_token } = response.token;
  storeToken(access_token);
  return response.user;
};

// Get User Info function
export const getUserInfo = async (): Promise<User | null> => {
  const token = getStoredToken();
  if (!token) {
    console.warn('No token found');
    return null;
  }

  try {
    return await fastapi<User>('get', '/user/me', {}, token);
  } catch (error) {
    console.error('사용자 정보를 가져오는데 실패했습니다:', error);
    return null;
  }
};
