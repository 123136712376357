import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';

// 로컬 스토리지에서 토큰을 가져오는 헬퍼 함수
const getStoredToken = (): string | null => {
  return localStorage.getItem('access_token');
};

// 현재 접속한 주소를 바탕으로 baseUrl을 구성합니다.
const baseUrl = `${window.location.origin}/api`;

// Axios 인스턴스를 생성합니다.
const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

interface FastAPIOptions {
  headers?: Record<string, string>;
}

const fastapi = <T, P extends Record<string, unknown> = Record<string, unknown>>(
  operation: 'get' | 'post' | 'put' | 'delete',
  url: string,
  params: P,
  token?: string,
  success_callback?: (data: T) => void,
  failure_callback?: (error: Error) => void,
  options?: FastAPIOptions
): Promise<T> => {
  const method = operation;

  // 로컬 스토리지에서 토큰을 가져옵니다.
  const authToken = token || getStoredToken();

  const axiosOptions: AxiosRequestConfig = {
    method: method,
    url: url,
    headers: {
      ...options?.headers,
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
    },
  };

  if (method.toLowerCase() === 'get') {
    axiosOptions.params = params;
  } else {
    axiosOptions.data = params;
  }

  return axiosInstance(axiosOptions)
    .then((response: AxiosResponse<T>) => {
      if (response.status === 204) {
        success_callback?.(undefined as T);
        return undefined as T;
      }
      success_callback?.(response.data);
      return response.data;
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.detail || error.message || 'An unknown error occurred';
      const customError = new Error(errorMessage);
      failure_callback?.(customError);
      throw customError;
    });
};

export default fastapi;
