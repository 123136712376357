import type { Classroom } from 'features/Classroom/ClassroomInterfaces';
import fastapi from 'features/Common/utils/fastapi';

export const getClassrooms = (token: string): Promise<Classroom[]> => {
  return fastapi<Classroom[]>('get', '/classrooms/', {}, token);
};

export const joinClassroom = (classroomId: string, token: string): Promise<void> => {
  return fastapi<void>('post', `/classrooms/${classroomId}/join`, {}, token);
};
