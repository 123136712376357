import type { Activity, ActivityCreate } from './ActivityInterfaces';
import fastapi from 'features/Common/utils/fastapi';

export const createActivity = async (
  activityData: ActivityCreate,
  token: string
): Promise<Activity> => {
  return fastapi<Activity>('post', '/api/activity', activityData as Record<string, unknown>, token);
};

export const getActivities = async (
  classroomId: string,
  token: string
): Promise<Activity[]> => {
  return fastapi<Activity[]>('get', '/api/activity', { classroom_id: classroomId }, token);
};

export const deleteActivity = async (
  activityId: string,
  token: string
): Promise<void> => {
  return fastapi<void>('delete', `/api/activity/${activityId}`, {}, token);
};